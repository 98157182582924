<template>
    <Device productKey="WATER_CONTROL"></Device>
</template>

<script>
    import Device from "./Device"
    export default {
        components:{Device}
    }
</script>

<style scoped>

</style>
